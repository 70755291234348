import React, { useState } from 'react'
import { Box, Flex, Text, FAIcon, Calendar } from '@fivehealth/botero'
import { faAngleDown } from '@fortawesome/pro-regular-svg-icons'
import { format } from 'date-fns'

const SelectDate = ({ defaultDate = new Date(), onDateChange, ...props }) => {
  const [showCalendar, setShowCalendar] = useState(false)

  return (
    <Box position="relative">
      <Box
        width="250px"
        borderWidth="1px"
        borderColor="mediumShade"
        borderRadius="8px"
        borderStyle="solid"
        padding="12px"
        cursor="pointer"
        {...props}
        onClick={() => {
          setShowCalendar(true)
        }}
      >
        <Flex justifyContent="space-between" alignItems="center">
          <Text
            fontSize="14px"
            fontWeight="400"
            lineHeight="24px"
            color="fullShade"
          >
            {format(defaultDate, 'dd/MM/yyyy')}
          </Text>
          <FAIcon icon={faAngleDown} color="darkestShade" />
        </Flex>
      </Box>
      {showCalendar && (
        <Box
          style={{
            position: 'absolute',
            zIndex: 5,
            left: 0,
            bottom: 50,
            backgroundColor: 'white',
          }}
        >
          <Calendar
            allowPreviousDate
            defaultDate={defaultDate}
            onChange={(date) => {
              setShowCalendar(false)
              onDateChange(date)
            }}
          />
        </Box>
      )}
    </Box>
  )
}

export default SelectDate
